<template>
  <div class="read-secret">
    <form @submit.prevent="query">
      <text-field v-model="hash" label="Enter hash" />
      <button type="submit">Query</button>
      <div class="secret" v-show="secret.length">
        <p><b>The secret text is: </b>"{{ secret }}"</p>
      </div>
      <div class="not-found" v-show="notFound">
        <span>The secret was not found.</span>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/services/api'
import TextField from '@/components/TextField'

export default {
  name: 'ReadSecret',
  components: { TextField },
  data: () => ({
    hash: '',
    secret: '',
    notFound: false
  }),
  methods: {
    clearResult () {
      this.secret = ''
      this.notFound = false
    },
    async query () {
      if (this.hash) {
        // clear last result
        this.clearResult()
        try {
          const res = await api.readSecret(this.hash)
          if (res.status === 200 && res.data?.secretText) {
            // found
            this.secret = res.data.secretText
            // console.log(res.data)
          } else {
            // not found
            this.notFound = true
          }
        } catch (e) {
          this.notFound = true
        }
        // clear form
        this.hash = ''
      }
    }
  }
}
</script>

<style>
.secret, .not-found {
  margin: 20px auto;
  padding: 20px 0;
  border-top: 1px solid black;
}
</style>
